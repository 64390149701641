import React from 'react'

const Wave = () => (
    <svg width="100%" height="160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M587.689 0C314.019 0 81.8671 37.3526 0 56.0289V160H2802V14.4404C2660.68 45.0542 2307.68 107.668 2026.21 113.213C1674.38 120.144 929.777 0 587.689 0Z" fill="#FFFFFF">
            <animate repeatCount="indefinite"
            fill="freeze" attributeName="d" dur="10s"
            values="M587.689 0C314.019 0 81.8671 37.3526 0 56.0289V160H2802V14.4404C2660.68 45.0542 2307.68 107.668 2026.21 113.213C1674.38 120.144 929.777 0 587.689 0Z;

            
            M1524.9 5.00505C880.991 -24.4399 266.346 85.1608 0 96.6116V160H2802V86.3876C2789.97 95.6573 2743.46 115.015 2653.7 118.286C2541.51 122.376 2168.82 34.45 1524.9 5.00505Z;

            M587.689 0C314.019 0 81.8671 37.3526 0 56.0289V160H2802V14.4404C2660.68 45.0542 2307.68 107.668 2026.21 113.213C1674.38 120.144 929.777 0 587.689 0Z
            "
            />
        </path>
     </svg>
)

export default Wave